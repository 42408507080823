import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { ComponentMotion } from "../../utils/componentMotion"

const Hero = ({ image, title, subtitle }) => {
  return (
    <section className="relative bg-secondary text-primary">
      <div className="absolute inset-x-0 bottom-0 h-1/2" />
      <div className="relative shadow-xl sm:overflow-hidden">
        <div className="h-56 sm:h-72 absolute md:right-0 md:h-full md:w-2/3">
          <GatsbyImage
            className="w-full h-full object-cover"
            image={image}
            alt=""
          />
          <div className="absolute inset-0 bg-gradient-to-r from-secondary" />
          <div className="absolute inset-0 bg-gradient-to-b from-secondary" />
          <div className="absolute inset-0 bg-gradient-to-t from-secondary" />
        </div>
        <ComponentMotion viewThreshold={0} yMotion={200}>
          <div className="layout relative py-12 lg:py-32">
            <div className="w-full md:w-3/4">
              <h1 className="text-left text-4xl font-extrabold sm:text-5xl md:text-6xl lg:text-7xl xl:text-9xl">
                <span className="block leading-tight"> {title}</span>
              </h1>
              <div className="w-full md:max-w-4xl">
                <p className="mt-6 md:mt-12 lg:mt-16 xl:mt-32 text-lg lg:text-2xl">
                  {subtitle}
                </p>
              </div>
            </div>
          </div>
        </ComponentMotion>
      </div>
    </section>
  )
}

export default Hero
