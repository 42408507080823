import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { ComponentMotion } from "../../utils/componentMotion"
import { Link } from "gatsby"

const HeroSecond = ({ image, title, subtitle, isEnglish }) => {
  return (
    <section className="relative bg-secondary text-primary">
      <div className="">
        <div className="relative">
          {/* Decorative image and overlay */}
          <div aria-hidden="true" className="absolute inset-0 overflow-hidden">
            <GatsbyImage
              image={getImage(image)}
              alt=""
              className="w-full h-full object-center object-cover"
            />
          </div>
          <div
            aria-hidden="true"
            className="absolute inset-0 bg-secondary opacity-50"
          />
          <ComponentMotion viewThreshold={0.2} yMotion={200}>
            <div className="layout relative py-32 flex flex-col text-left sm:py-64 lg:px-0">
              <h1 className="text-3xl font-extrabold tracking-tight  lg:text-5xl">
                {title}
              </h1>
              <Link
                to={isEnglish ? "/en/contact" : "/contact"}
                className="mt-6 text-lg xl:text-2xl"
              >
                {subtitle}
              </Link>
              {/* <Link
              to={"mailto:info@rigowoodworks.com"}
              className="inline-block text-center bg-secondary hover:bg-tertiary border border-transparent rounded-md py-3 px-8 font-medium text-white "
            >
              {isEnglish ? "Get in touch" : "Contactez-nous"}
            </Link> */}
            </div>
          </ComponentMotion>
        </div>
      </div>
    </section>
  )
}

export default HeroSecond
