import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { ComponentMotion } from "../../utils/componentMotion"

const ShopBanner = ({ title, subtitle, buttonText, images, isEnglish }) => {
  return (
    <section className="relative bg-primary">
      <div className="pt-12 lg:pt-24 lg:pb-12">
        <div className="">
          {/* <div
          aria-hidden="true"
          className="absolute inset-0 bg-secondary opacity-50"
        /> */}
          <div className="layout relative">
            <div className="">
              <h1 className="text-4xl font font-extrabold xl:text-5xl">
                {title}
              </h1>
              <p className="mt-3 lg:mt-6 text-lg xl:text-2xl">{subtitle}</p>
            </div>
            <div className="mt-6">
              <Link
                to={isEnglish ? "/en/shop" : "/shop"}
                className="inline-block text-center bg-secondary hover:bg-tertiary border border-transparent rounded-md py-3 px-8 font-medium text-white "
              >
                {buttonText}
              </Link>
            </div>
          </div>

          <div
            aria-hidden="true"
            className="layout md:inset-y-64 md:inset-x-32 lg:inset-y-52 lg:inset-x-32 xl:inset-40 py-6 md:py-0"
          >
            <Link className="flex" to={isEnglish ? "/en/shop" : "/shop"}>
              <ComponentMotion viewThreshold={0.2} yMotion={200}>
                <GatsbyImage className="" image={getImage(images[0])} alt="" />
              </ComponentMotion>
              <ComponentMotion viewThreshold={0.2} yMotion={260} viewDelay={20}>
                <GatsbyImage className="" image={getImage(images[1])} alt="" />
              </ComponentMotion>
              <ComponentMotion viewThreshold={0.2} yMotion={280}>
                <GatsbyImage className="" image={getImage(images[2])} alt="" />
              </ComponentMotion>
            </Link>
          </div>
          {/* <div className=" col-span-1 overflow-hidden">
              <div className="">
                <div aria-hidden="true" className="pointer-events-none">
                  <div className="">
                    <div className="flex items-center space-x-6 lg:space-x-8">
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-32 h-32 lg:w-48 lg:h-48 rounded-lg bg-white sm:opacity-0 lg:opacity-100">
                          <GatsbyImage
                            image={getImage(images[0])}
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-32 h-32 lg:w-48 lg:h-48 rounded-lg bg-white ">
                          <GatsbyImage
                            image={getImage(images[1])}
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                      </div>
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-32 h-32 lg:w-48 lg:h-48 rounded-lg bg-white ">
                          <GatsbyImage
                            image={getImage(images[2])}
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-32 h-32 lg:w-48 lg:h-48 rounded-lg bg-white ">
                          <GatsbyImage
                            image={getImage(images[3])}
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-32 h-32 lg:w-48 lg:h-48 rounded-lg bg-white ">
                          <GatsbyImage
                            image={getImage(images[4])}
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                      </div>
                      <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="w-32 h-32 lg:w-48 lg:h-48 rounded-lg bg-white ">
                          <GatsbyImage
                            image={getImage(images[5])}
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                        <div className="w-32 h-32 lg:w-48 lg:h-48 rounded-lg bg-white ">
                          <GatsbyImage
                            image={getImage(images[6])}
                            alt=""
                            className="w-full h-full object-center object-cover"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
        </div>
      </div>
    </section>
  )
}

export default ShopBanner
