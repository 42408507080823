import * as React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Hero from "../components/section/hero"
import HeroSecond from "../components/section/hero-second"
import { Layout } from "../layouts/layout"
import ShopBanner from "../components/section/shop-banner"
import Claim from "../components/section/claim"
import { Seo } from "../components/seo"

const Home = ({ data }) => {
  let isEnglish = data.context.context.nodeLocale === "en-CA"
  const path = ""

  const {
    heroImage,
    slogan,
    heroText,
    shopBannerTitle,
    shopBannerSubtitle,
    shopBannerButton,
    galleryImage,
    heroSecondImage,
    heroSecondTitle,
    heroSecondSubtitle,
    claimText,
  } = data.page

  return (
    <Layout isEnglish={isEnglish} path={path}>
      <Hero image={getImage(heroImage)} title={slogan} subtitle={heroText} />
      <ShopBanner
        title={shopBannerTitle}
        subtitle={shopBannerSubtitle}
        buttonText={shopBannerButton}
        images={galleryImage}
        isEnglish={isEnglish}
      />
      <Claim subtitle={claimText.claimText} />
      <HeroSecond
        image={heroSecondImage}
        title={heroSecondTitle}
        subtitle={heroSecondSubtitle}
        isEnglish={isEnglish}
      />
    </Layout>
  )
}

export default Home

export const Head = () => <Seo />

export const query = graphql`
  query HomePage($slug: String!, $nodeLocale: String!) {
    page: contentfulHomePage(
      slug: { eq: $slug }
      node_locale: { eq: $nodeLocale }
    ) {
      node_locale
      id
      heroImage {
        gatsbyImageData
      }
      title
      slug
      slogan
      heroText
      shopBannerImage {
        gatsbyImageData
      }
      shopBannerTitle
      shopBannerSubtitle
      shopBannerButton
      claimText {
        claimText
      }
      heroSecondTitle
      heroSecondSubtitle
      heroSecondImage {
        gatsbyImageData
      }
      galleryImage {
        id
        gatsbyImageData
        title
      }
      slug
    }
    context: sitePage(
      context: { nodeLocale: { eq: $nodeLocale }, slug: { eq: $slug } }
    ) {
      id
      path
      context {
        slug
        nodeLocale
        pathNoLanguagePrefix
      }
    }
    projects: allContentfulProjectItem(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      nodes {
        title
        slug
        pictureFeature {
          gatsbyImageData(aspectRatio: 0.7)
        }
        id
      }
    }
  }
`
