import * as React from "react"

const Claim = ({ title, subtitle, buttonText, images, isEnglish }) => {
  return (
    <section className="relative bg-secondary text-primary">
      <div className="py-16 lg:py-24 layout">
        <div className="w-full px-0 lg:px-24 xl:px-48">
          {/* <div
          aria-hidden="true"
          className="absolute inset-0 bg-secondary opacity-50"
        /> */}
          <div className="border-t border-b border-primary divider">
            <p className="px-1 py-6 lg:py-12 text-lg xl:text-2xl tracking-wide">
              {subtitle}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Claim
